import styled from '@emotion/styled'
import { useRouter } from 'next/router'

import onClickEvents from '@helpers/home/on-click-callbacks'
import getPath from '@helpers/path'
import ROUTES from '@helpers/routes'
import { TEST_IDS } from '@stories/home/test/constants'
import { breakpoint } from 'theme'

import { arrayOf, bool, func, oneOfType, shape, string } from 'prop-types'

const FourTileLayout = (props) => {
  const router = useRouter()

  if (props.tiles) {
    const bannerName = props.banner_name

    return (
      <TileContainer data-e2eid={TEST_IDS.FOUR_TILE_LAYOUT}>
        <TitleContainer>
          <Title>{props.section_title}</Title>
          <TitleDescription>{props.section_text}</TitleDescription>
        </TitleContainer>
        {props.tiles &&
          props.tiles.map((tile, i) => {
            const bannerItem = tile.banner_item
            const tileRedirectLocation = tile.link ? tile.link.url : ROUTES.MENU
            const { href: tileRedirect } = getPath(
              { pathname: tileRedirectLocation, query: router.query },
              { removeParams: ['slug'] }
            )

            return (
              <Tile key={i} onClick={() => onClickEvents(bannerName, tileRedirect, props.toggleAddress, bannerItem)}>
                <TileContent index={i}>{tile.link.title}</TileContent>
                <TileBackground />
              </Tile>
            )
          })}
      </TileContainer>
    )
  } else {
    return null
  }
}

FourTileLayout.propTypes = {
  banner_name: string,
  section_title: oneOfType([bool, string]),
  section_text: oneOfType([bool, string]),
  tiles: oneOfType([
    bool,
    arrayOf(
      shape({
        image: shape({
          url: string,
          link_text: string
        })
      })
    )
  ]),
  toggleAddress: func
}

const Title = styled.h3`
  font-size: ${({ theme }) => theme.typography.size.mobile.largeTitle}px;
  font-family: ${({ theme }) => theme.typography.font.npBold};
  margin-bottom: ${({ theme }) => theme.spacing.xs}px;
  text-transform: lowercase;
  &::first-letter {
    text-transform: uppercase;
  }
  @media (min-width: ${breakpoint.min.md}px) {
    font-size: ${({ theme }) => theme.typography.size.desktop.title2}px;
  }
`

const TitleContainer = styled.div`
  margin-top: 50px;
  width: 100%;
  text-align: center;
`

const TitleDescription = styled.p`
  font-family: ${({ theme }) => theme.typography.font.sim};
  font-size: ${({ theme }) => theme.typography.size.mobile.body3}px;
  margin: 0;
`

const TileContent = styled.div<{ index: number }>`
  border: solid ${({ theme }) => theme.colors.border};
  border-width: 1px;
  border-radius: 8px;
  color: black;
  display: flex;
  font-family: ${({ theme }) => theme.typography.font.npBold};
  font-size: ${({ theme }) => theme.typography.size.mobile.title2}px;
  height: calc(100% - 6px);
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  text-transform: capitalize;
  width: calc(100% - 6px);
  z-index: 2;

  ${({ index }) =>
    index === 0 && 'background: linear-gradient(160deg, rgba(157,234,160,1) 0%, rgba(223,240,191,1) 100%);'};
  ${({ index }) =>
    index === 1 && 'background: linear-gradient(160deg, rgba(252,193,123,1) 0%, rgba(249,238,195,1) 100%);'};
  ${({ index }) =>
    index === 2 && 'background: linear-gradient(35deg, rgba(251,197,176,1) 0%, rgba(251,101,134,1) 100%);'};
  ${({ index }) =>
    index === 3 && 'background: linear-gradient(35deg, rgba(233,204,221,1) 0%, rgba(204,186,232,1) 100%);'};

  @media (min-width: 768px) {
    border-width: 2px;
    font-size: ${({ theme }) => theme.typography.size.desktop.title3}px;
    height: calc(100% - 12px);
    width: calc(100% - 12px);
  }
`

const TileBackground = styled.div`
  border: solid ${({ theme }) => theme.colors.border};
  border-radius: 8px;
  border-width: 1px;
  height: calc(100% - 6px);
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc(100% - 6px);
  z-index: 1;

  @media (min-width: ${breakpoint.min.md}px) {
    border-width: 2px;
    height: calc(100% - 12px);
    width: calc(100% - 12px);
  }
`

const TileContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 45px;
  margin: 0 auto 140px;
  max-width: 270px;
  padding: 0;

  @media (min-width: ${breakpoint.min.md}px) {
    max-width: ${breakpoint.min.lg}px;
    padding: 0 20px;
  }

  @media (min-width: ${breakpoint.min.lg}px) {
    padding: 0 20px;
  }
`

const Tile = styled.button`
  flex: 1;
  display: flex;
  flex-flow: row wrap;
  height: 80px;
  justify-content: center;
  position: relative;
  min-width: 265px;
  background: none;
  padding: 0;
  cursor: pointer;

  @media (min-width: ${breakpoint.min.md}px) {
    height: 136px;
    min-width: 400px;
    max-width: 500px;
  }
`

export default FourTileLayout
