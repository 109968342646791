import t from './actionTypes'

// Opens or closes the referral modal on the menu
export function toggleReferralModal() {
  return {
    type: t.TOGGLE_REFERRAL_MODAL
  }
}

// Opens the iOS vapes redirect modal and sets the path the button should redirect to
export function openIosVapesRedirectModal(redirectPath: string) {
  return {
    type: t.OPEN_IOS_VAPES_REDIRECT_MODAL,
    payload: redirectPath
  }
}

// Closes the iOS vapes redirect modal
export function closeIosVapesRedirectModal() {
  return {
    type: t.CLOSE_IOS_VAPES_REDIRECT_MODAL
  }
}

export function togglePersonaModal() {
  return (dispatch, getState) => {
    const personaModalState = getState().modal.isPersonaModalOpen

    if (personaModalState) {
      dispatch(closePersonaModal())
    } else {
      dispatch(openPersonaModal())
    }
  }
}

// open the Persona modal
export function openPersonaModal() {
  return {
    type: t.OPEN_PERSONA_MODAL
  }
}

// close the Persona modal
export function closePersonaModal() {
  return {
    type: t.CLOSE_PERSONA_MODAL
  }
}
