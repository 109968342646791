import { useEffect } from 'react'

import styled from '@emotion/styled'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'

import { setRedirectLocation, setShouldRedirect, toggleAddress } from '@/redux/addressModal/actions'
import { getResidentialOnlyError } from '@/redux/addressModal/selectors'
import { getBannerKeys, getVisibleBannerKey } from '@/redux/alert/selectors'
import { handleHomeLocation } from '@/redux/blog/operations'
import { getAddressLoading } from '@/redux/loading/selectors'
import { getActiveLocation } from '@/redux/location/selectors'
import Footer from '@/stories/footer'
import LocationForm from '@/stories/location-form'
import Modal from '@/stories/modals/container'
import Navbar from '@/stories/navbar/component'
import getPath from '@helpers/path'
import ROUTES from '@helpers/routes'
import { TEST_IDS } from '@stories/blog/test/constants'
import { track } from 'analytics'

import FourTileLayout from './four-tile-layout'
import LogoGallery from './logo-gallery'
import { StaticProductGallery } from './product-gallery'
import ThreeColumnLayout from './three-column-layout'

import { arrayOf, object, shape } from 'prop-types'

function Homepage({ pageACF }) {
  const dispatch = useDispatch()
  const router = useRouter()
  const addressLoading = useSelector(getAddressLoading)
  const activeLocation = useSelector(getActiveLocation)
  const residentialOnlyError = useSelector(getResidentialOnlyError)
  const bannerKeys = useSelector(getBannerKeys)
  const bannerKey = getVisibleBannerKey(bannerKeys, router.pathname, router.query)
  const hasBanner = Boolean(bannerKey)
  useEffect(() => {
    dispatch(setShouldRedirect(true))
    const { href: menuPathWithQuery } = getPath(
      { pathname: ROUTES.MENU, query: router.query },
      { removeParams: ['slug'] }
    )
    dispatch(setRedirectLocation(menuPathWithQuery))

    return () => {
      dispatch(setShouldRedirect(false))
      dispatch(setRedirectLocation(''))
    }
  }, [dispatch, router.query])

  const handleLocationChange = (address) => {
    dispatch(handleHomeLocation(address))
  }

  const handleAddressSubmission = (redirectLocation) => {
    track('Places.View', { destination: redirectLocation })
    dispatch(toggleAddress(redirectLocation))
  }

  const componentToRender = (layoutName) => {
    const components = {
      three_column_layout: ThreeColumnLayout,
      four_tile_layout: FourTileLayout,
      product_gallery: StaticProductGallery,
      logo_gallery: LogoGallery
    }

    return components[layoutName]
  }

  return (
    <PageContainer data-e2eid={TEST_IDS.PAGE}>
      <Head>
        <link rel="canonical" href={`https://www.eaze.com/`} key="canonical" />
      </Head>
      <Navbar hasBanner={hasBanner} showLoginShopButtons />

      <LocationForm
        activeLocation={activeLocation}
        addressLoading={addressLoading}
        formLocation="header"
        onLocationChange={handleLocationChange}
        residentialOnlyError={residentialOnlyError}
        showTitleDecoration
        subtitle="Enter your address to check availability in your area."
        title="Highly calculated cannabis delivery"
        useLargeContainer={true}
      />

      {pageACF.page_sections.map((layout) => {
        const layoutName = layout.acf_fc_layout
        const CustomComponent = componentToRender(layoutName)
        return <CustomComponent key={layoutName} toggleAddress={handleAddressSubmission} {...layout} />
      })}

      <LocationForm
        activeLocation={activeLocation}
        addressLoading={addressLoading}
        formLocation="footer"
        onLocationChange={handleLocationChange}
        residentialOnlyError={residentialOnlyError}
        showTitleDecoration
        title="Get your weed today"
      />

      <Footer />

      <Modal />
    </PageContainer>
  )
}

Homepage.propTypes = {
  pageACF: shape({
    page_sections: arrayOf(object)
  }).isRequired
}

const PageContainer = styled.div`
  width: 100%;
  overflow: hidden;
  background-color: white;
`

export default Homepage
